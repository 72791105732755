import {
  AFF_NET,
  ConsentContextType,
  COOKIE_BANNER_USER_PREF,
  CookieConsentCategory,
  EditableCookieConsentCategory,
  R2O_ENV,
  R2O_OS,
  R2O_REFERRER,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_CONTENT
} from '../types/cookieType';

export const getCookie = (name: string): string | null => {
  if (typeof window !== 'undefined') {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
  return null;
};

export const truncateCookie = (cookieName: string, maxLength: number = 255): string | null => {
  const cookieValue = getCookie(cookieName);
  return cookieValue ? cookieValue.slice(0, maxLength) : null;
};

export const getCookieExpires = (days: number) => {
  if (days < 0) {
    throw new Error('Number of days cannot be negative');
  }
  const cookieDate = new Date();
  cookieDate.setDate(cookieDate.getDate() + days);
  return cookieDate.toUTCString();
};

export function getDocumentCookieFormat(name: string, value: string, domain: string | null = null, expiration: string | null = null) {
  const cookieExpires = expiration ? `; expires=${expiration}` : '';
  const cookieDomain = domain ? `; domain=${domain}` : '';
  return `${name}=${value}${cookieExpires}${cookieDomain}; path=/`;
}

export const deleteCookie = (name: string) => {
  // Setting to 0 did not work consistently https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie#notes
  // choosing to use a date in the past instead
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

// TODO INTSERVICE-1289 third party services cookies that need to be categorized
export const getMarketingCookieValues = () => {
  const cookies = ['_ga', 'gclid', 'fbclid', '_fbc', '_fbp'];
  const cookieValues = {};
  cookies.forEach(c => {
    const cValue = getCookie(c);
    if (cValue !== null) {
      cookieValues[c] = cValue;
    }
  });

  return cookieValues;
};

export const CookieCategorised: Record<CookieConsentCategory, Set<string>> = {
  [CookieConsentCategory.ESSENTIAL]: new Set([UTM_SOURCE, UTM_MEDIUM, UTM_CAMPAIGN, UTM_CONTENT, R2O_REFERRER, R2O_ENV, R2O_OS, AFF_NET]),
  [CookieConsentCategory.ADVERTISING]: new Set([]),
  [CookieConsentCategory.PERSONALISED_ADVERTISING]: new Set([]),
  [CookieConsentCategory.USER_ANALYTICS]: new Set([])
};

export const getCookieConsentCategory = (cookie: string): CookieConsentCategory | null => {
  for (const [cookieCategoryType, cookies] of Object.entries(CookieCategorised)) {
    if (cookies.has(cookie)) {
      return cookieCategoryType as CookieConsentCategory;
    }
  }

  return null;
};

export const getCookieConsentBannerStoredPreferences = (): ConsentContextType['acceptedCategories'] | null => {
  const ccUserPref = JSON.parse(getCookie(COOKIE_BANNER_USER_PREF));
  const validCategories = [CookieConsentCategory.ESSENTIAL];

  if (ccUserPref) {
    for (const category of Object.values(EditableCookieConsentCategory)) {
      if (ccUserPref.categories.includes(category)) {
        validCategories.push(category);
      }
    }

    return new Set(validCategories);
  }

  return null;
};
