const staticValues = require('./config-static');

// Gatsby does not provide proces.env vars by default to code
// executed inside templates. (gatsby-node code does have access to it)
// To avoid import error from throwInvalidEnvException we separate
// config values that are static from values grabbed from process.env
// Keep in mind, there's the option of prepending GATSBY_ to an ENV var
// and Gatsby will make it available, but then we'd have to access it directly
// and not via config. Or update all ENV vars to have this value prepended
// ATM we will use the separate config files, but this could change in the future
// if we require it to

module.exports = {
  config: {
    ...staticValues,
    datoApiFullAccessToken: process.env.DATO_API_FULL_ACCESS_TOKEN,
    datoApiReadOnlyToken: process.env.DATO_API_READ_ONLY_ACCESS_TOKEN,
    gatsbyActiveEnv: process.env.GATSBY_ACTIVE_ENV || throwInvalidEnvException('GATSBY_ACTIVE_ENV'),
    siteUrl: process.env.SITE_URL || throwInvalidEnvException('SITE_URL'),
    assetPrefixUrl: process.env.ASSET_PREFIX_URL,
    vwoId: process.env.VWO_ID,
    draftContent: process.env.DRAFT_CONTENT === 'true',
    metaNoIndex: process.env.META_NO_INDEX === 'true',
    googleTagManagerId: process.env.GOOGLE_TAG_MANAGER_ID,
    segmentKey: process.env.SEGMENT_KEY || throwInvalidEnvException('SEGMENT_KEY'),
    signupRequestToken: process.env.SIGNUP_REQUEST_TOKEN,
    signupCreateAccountUrl: process.env.SIGNUP_CREATE_ACCOUNT_URL,
    signupConfirmUrl: process.env.SIGNUP_CONFIRM_URL,
    signupResendUrl: process.env.SIGNUP_RESEND_URL,
    signupCompleteSignup: process.env.SIGNUP_COMPLETE_SIGNUP,
    jwtSecret: process.env.JWT_SECRET,
    loveCustomerCheckEmailUrl: process.env.LOVE_CUSTOMER_CHECK_EMAIL_URL,
    posLoginUrl: process.env.POS_LOGIN_URL,
    recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY,
    apiRequestUrl: process.env.API_REQUEST_URL,
    apiRequestToken: process.env.API_REQUEST_TOKEN,
    sentryDsn: process.env.SENTRY_DSN,
    helpDocsKey: process.env.HELPDOCS_API_KEY,
    slack: {
      webhook: process.env.SLACK_WEBHOOK,
      channel: process.env.SLACK_NOTIFICATION_CHANNEL,
      marketingGroupID: 'subteam^S01AVFPAF45|marketing-team',
      warnBotGroupID: 'subteam^S04CWDKLWH4|phoenix',
      enabled: process.env.SLACK_NOTIFICATION_ENABLED === 'true'
    },
    releaseName: process.env.RELEASE_NAME,
    sentryEnabled: function () {
      return this.isProduction() || this.isContentPreview();
    },
    isProduction: function () {
      return this.gatsbyActiveEnv === 'production';
    },
    isCommitPreview: function () {
      return this.gatsbyActiveEnv === 'commitpreview';
    },
    isContentPreview: function () {
      return this.gatsbyActiveEnv === 'contentpreview';
    },
    caseManagementAPI: process.env.SERVICES_API_BASE + process.env.CASE_MANAGEMENT_API || throwInvalidEnvException('CASE_MANAGEMENT_API'),
    emailSubscribeAPI: {
      newsletter: process.env.SERVICES_API_BASE + process.env.NEWSLETTER_SUBSCRIBE_API || throwInvalidEnvException('NEWSLETTER_SUBSCRIBE_API'),
      hardware: process.env.SERVICES_API_BASE + process.env.EMAIL_SUBSCRIBE_API || throwInvalidEnvException('EMAIL_SUBSCRIBE_API')
    },
    salesforce: {
      sitesBaseUrl: process.env.SALESFORCE_SITES_BASE_URL,
      lightningOutSite: `${process.env.SALESFORCE_SITES_BASE_URL}/lightningout`,
      lightningOutScriptUrl: `${process.env.SALESFORCE_SITES_BASE_URL}/lightningout/lightning/lightning.out.js`
    }
  }
};

function throwInvalidEnvException(envName) {
  throw new Error(`Invalid env variable value for ${envName}`);
}
