const React = require('react');
const PageViewTracker = require('./src/components/PageViewTracker').PageViewTracker;

const {config} = require('./config');

const getChromeVersion = () => {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
};

exports.onClientEntry = function (_, pluginParams) {
  import('@sentry/browser').then(Sentry => {
    Sentry.init({
      dsn: config.sentryDsn,
      environment: config.gatsbyActiveEnv,
      enabled: config.sentryEnabled(),
      release: config.releaseName,
      async beforeSend(event, hint) {
        const userAgent = navigator.userAgent;
        if (userAgent.includes('bot') || userAgent.includes('spider')) {
          return null; // Ignore bot traffic
        }
        const isInternetExplorer = window.navigator.userAgent.match(/MSIE|Trident/) !== null;
        if (isInternetExplorer) {
          return null;
        }
        if (event.exception.values[0].value === '{"isTrusted":false}') {
          return null;
        }
        const chromeVersion = getChromeVersion();
        if (chromeVersion && chromeVersion < 72) {
          return null;
        }
        return event;
      },
      blacklistUrls: [
        // Olark Scripts
        /static\.olark\.com/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i
      ],
      ignoreErrors: [
        "Can't execute code from a freed script", // iframe issues for external scripts
        "Can't find variable: Intl", // Unsupported browsers using intl
        'ChunkLoadError', // Happens due to users network issues
        "null is not an object (evaluating 'n.title')", // optinmonster issue
        /Object Not Found Matching Id/ // comes from CefSharp
      ]
    });
    window.Sentry = Sentry;
  });
};

exports.onRouteUpdate = ({_, prevLocation}) => {
  window.onRouteUpdateGatsbyPrevLocation = prevLocation?.href || document.referrer;
};

exports.wrapPageElement = ({element, props}) => {
  return <PageViewTracker {...props}>{element}</PageViewTracker>;
};
